import { FormOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCheckRole } from '../../hook/useCheckRoles';
import AddPrincipalDocument from '../Modal/AddPrincipalContract/AddPrincipalContract';

export default function AddPrincipalContractButton(props) {
  const [isShowAddNewDocument, setIsShowAddNewDocument] = useState(false);
  const { checkRoleDocument } = useCheckRole();
  const { t } = useTranslation();

  const styles = {
    button: {
      height: '40px',
      width: 'calc(100% - 20px)',
      minWidth: !props?.collapsed ? '50px' : '100%',
      marginBottom: '10px',
      backgroundColor: !props?.collapsed ? '#2DB7F5' : 'transparent',
      color: !props?.collapsed ? 'white' : '#2DB7F5'
    }
  };
  const onClick = () => {
    if (checkRoleDocument('CREATE')) {
      setIsShowAddNewDocument(true);
    }
  };

  return (
    <Fragment>
      <Button
        type={!props?.collapsed ? 'default' : 'ghost'}
        style={styles.button}
        icon={<FormOutlined />}
        onClick={onClick}
      >
        {!props?.collapsed && t('dashboard.document.newPrincipalContractBtn')}
      </Button>
      {isShowAddNewDocument && <AddPrincipalDocument onOpen={setIsShowAddNewDocument} isOpen={isShowAddNewDocument} />}
    </Fragment>
  );
}
