import { Layout } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
const { Footer: FooterAntd } = Layout;

export default function Footer() {
  return (
    <FooterAntd
      style={{
        textAlign: 'center'
      }}
    >
      2023 ©{' '}
      <Link to='https://martech.com.vn/' target='_blank'>
        Martech JSC
      </Link>
      . All rights reserved.
    </FooterAntd>
  );
}
