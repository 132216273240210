import { Spin } from 'antd';
import { useEffect } from 'react';
import { signinRedirect } from 'src/utils/auth';

export default function SignoutCallbackOidc() {
  useEffect(() => {
    async function signinAsync() {
      await signinRedirect();
    }
    signinAsync();
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <Spin size='large' />
    </div>
  );
}
