import { menuResource } from 'src/constant';
import dayjs from 'dayjs';
import urlPath from 'src/constants/urlPath';

export function convertToUnsigned(str) {
  // Dùng bảng chữ cái có dấu và không dấu để thay thế
  var withSigns =
    'àáảãạâầấẩẫậăắằẳẵặèéẻẽẹêềếểễệđìíỉĩịòóỏõọôồốổỗộơờớởỡợùúủũụưừứửữựỳýỷỹỵÀÁẢÃẠÂẦẤẨẪẬĂẮẰẲẴẶÈÉẺẼẸÊỀẾỂỄỆĐÌÍỈĨỊÒÓỎÕỌÔỒỐỔỖỘƠỜỚỞỠỢÙÚỦŨỤƯỪỨỬỮỰỲÝỶỸỴ';
  var withoutSigns =
    'aaaaaaaaaaaaaaaaaeeeeeeeeeeediiiiiooooooooooooooooouuuuuuuuuuuyyyyyAAAAAAAAAAAAAAAAAEEEEEEEEEEEDIIIIIOOOOOOOOOOOOOOOOOUUUUUUUUUUUYYYYY';

  // Chuyển đổi từng ký tự của chuỗi
  str = str.replace(/./g, function (character, index) {
    var charIndex = withSigns.indexOf(character);
    return charIndex !== -1 ? withoutSigns[charIndex] : character;
  });
  return str;
}

export function convertDepartmentTree(list) {
  const getChild = (id) => {
    return getData(list?.filter((item) => item.parentId === id));
  };

  const getData = (list) => {
    return list?.map((item) => {
      return {
        value: item.id,
        title: item.name,
        code: item.code,
        children: getChild(item.id)
      };
    });
  };

  const convert = (list) => {
    return getData(list?.filter((item) => item.parentId === null));
  };

  return convert(list);
}

export const formatDate = (date) => {
  if (date) return dayjs(date).format('DD/MM/YYYY');
};
export const formatDateTime = (date) => {
  if (date) return dayjs(date).format('HH:mm DD/MM/YYYY');
};

export const convertOrderString = (field, order) => {
  switch (order) {
    case 'ascend':
      return `${field} asc`;
    case 'descend':
      return `${field} desc`;
    default:
      return undefined;
  }
  // if (field && order) {
  //   return `${field} ${order}`;
  // }
  // return undefined;
};

export const getPreviousPage = (key) => {
  const selectedMenu = Object.values(menuResource).find((item) => item.key === key);
  if (selectedMenu) {
    return selectedMenu.pathRoute;
  }
  return urlPath.waittingDocument.location;
};

export const getContentType = (type) => {
  return type.toString() + '; charset=utf-8;';
};

export const getParamItem = (key, value) => {
  return { key: key, value: value };
};

export const convertCapitalized = (string) => {
  if (string) {
    let lower = string?.toLowerCase();
    let words = lower?.split(' ');
    for (let i = 0; i < words.length; i++) {
      if (Boolean(words[i])) {
        words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
      }
    }
    return words?.join(' ');
  }
};

export const signedDate = (signDate) => {
  if (signDate) {
    return signDate.replaceAll('-', '/');
  } else {
    return '--/--/----';
  }
};

export const scroll = (anchors, anchor, className) => {
  let index = anchors?.map((x) => x.id)?.indexOf(anchor);
  const element = document.getElementsByClassName(className);
  element &&
    element.length > 0 &&
    element[index]?.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'nearest'
    });
};

export function getLastItem(arr) {
  if (arr.length === 0) {
    return undefined;
  }
  return arr[arr.length - 1];
}

export function hightlightSearch(content, searchCondition) {
  if (!content) return '';

  const index = content?.toLowerCase().indexOf(searchCondition?.toLowerCase());
  if (index === -1) return content;

  const searchLength = searchCondition.length;
  return `${content.substring(0, index)}<span style="font-weight: 500; color: red ">${content.substring(
    index,
    index + searchLength
  )}</span>${content.substring(index + searchLength)}`;
}

export function checkShowPartner(content, searchCondition) {
  let index = -1;
  if (!!content && !!searchCondition) {
    index = content.toLowerCase().indexOf(searchCondition.toLowerCase());
  }
  return index === -1 ? false : true;
}

export function getAlphabet() {
  return [...Array(26)].map((_, i) => String.fromCharCode(i + 97));
}

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function truncateString(str) {
  if (str.length > 30) {
    return str.substring(0, 30) + '...';
  } else {
    return str;
  }
}

export function convertToTimeZoneWithoutOffset(dateString) {
  const date = new Date(dateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const dateWithoutOffset = new Date(date.getTime() - userTimezoneOffset);

  return dateWithoutOffset;
}
