import { createContext, useEffect, useState } from 'react';
import { rightToolBar } from 'src/constant';
import { AdminMenuEnum } from 'src/constants/adminMenu.enum';

const initData = {
  document: {},
  setDocument: () => null,
  documentOrigin: {},
  setDocumentOrigin: () => null,
  isOpenDocumentAdmin: false,
  setIsOpenDocumentAdmin: () => null,
  isLeftSidebarCollapsed: false,
  setIsLeftSidebarCollapsed: () => null,
  isRightSidebarCollapsed: true,
  setIsRightSidebarCollapsed: () => null,
  adminSelectedKey: AdminMenuEnum.PARTNER_ACCESS_LOG,
  setAdminSelectedKey: () => null,
  selectedParagraph: undefined, // { appendixId, dataItem }
  setSelectedParagraph: () => null,
  inviteWithRole: [],
  setInviteWithRole: () => null,
  attachments: [],
  setAttachments: () => null,
  isShowAddAppendixModal: false,
  setIsShowAddAppendixModal: () => null,
  isShowInputMailModal: false,
  setIsShowInputMailModal: () => null,
  isShowWaitingDocument: false,
  setIsShowWaitingDocument: () => null,
  isShowSendDocumentModal: false,
  setIsShowSendDocumentModal: () => null
};

export const DocumentContext = createContext(initData);

export const DocumentProvider = ({ children }) => {
  const [document, setDocument] = useState(initData.document);
  const [documentOrigin, setDocumentOrigin] = useState(initData.documentOrigin);
  const [isOpenDocumentAdmin, setIsOpenDocumentAdmin] = useState(initData.isOpenDocumentAdmin);
  const [isShowAddAppendixModal, setIsShowAddAppendixModal] = useState(initData.isShowAddAppendixModal);
  const [isShowInputMailModal, setIsShowInputMailModal] = useState(initData.isShowAddAppendixModal);
  const [isShowWaitingDocument, setIsShowWaitingDocument] = useState(initData.isShowWaitingDocument);
  const [isShowSendDocumentModal, setIsShowSendDocumentModal] = useState(initData.isShowSendDocumentModal);

  const [isLeftSidebarCollapsed, setIsLeftSidebarCollapsed] = useState(initData.isLeftSidebarCollapsed);
  const [isRightSidebarCollapsed, setIsRightSidebarCollapsed] = useState(initData.isRightSidebarCollapsed);
  const [adminSelectedKey, setAdminSelectedKey] = useState(initData.adminSelectedKey);
  const [selectedParagraph, setSelectedParagraph] = useState(initData.selectedParagraph);
  const [ratioZoom, setRatioZoom] = useState([]);
  const [currentPaperWidth, setCurrentPaperWidth] = useState(undefined); // 749:  size A4 dọc
  const [viewMode, setViewMode] = useState(rightToolBar.Comment);
  const [prevChangeCount, setPrevChangeCount] = useState(undefined);
  const [inviteWithRole, setInviteWithRole] = useState(initData.inviteWithRole);
  const [attachments, setAttachments] = useState(initData.attachments);

  useEffect(() => {
    window.addEventListener('resize', () => {
      // const paperWidth = 794;

      const leftSidebarWidth = isLeftSidebarCollapsed ? 50 : 470;
      const rightSidebarWidth = isRightSidebarCollapsed ? 50 : 470;
      const currentPaperWidth = window.innerWidth - leftSidebarWidth - rightSidebarWidth;
      setCurrentPaperWidth(currentPaperWidth);
      // const ratio = currentPaperWidth / paperWidth;

      // if (ratio > 1) {
      //   setRatioZoom(1);
      // } else if (ratio < 0.8) {
      //   setRatioZoom(0.8);
      // } else {
      //   setRatioZoom(ratio);
      // }
    });
  }, [isLeftSidebarCollapsed, isRightSidebarCollapsed]);

  return (
    <DocumentContext.Provider
      value={{
        document,
        setDocument,
        documentOrigin,
        setDocumentOrigin,
        isOpenDocumentAdmin,
        setIsOpenDocumentAdmin,
        adminSelectedKey,
        setAdminSelectedKey,
        isLeftSidebarCollapsed,
        setIsLeftSidebarCollapsed,
        isRightSidebarCollapsed,
        setIsRightSidebarCollapsed,
        selectedParagraph,
        setSelectedParagraph,
        ratioZoom,
        setRatioZoom,
        viewMode,
        setViewMode,
        prevChangeCount,
        setPrevChangeCount,
        currentPaperWidth,
        setCurrentPaperWidth,
        inviteWithRole,
        setInviteWithRole,
        attachments,
        setAttachments,
        isShowAddAppendixModal,
        setIsShowAddAppendixModal,
        isShowInputMailModal,
        setIsShowInputMailModal,
        isShowWaitingDocument,
        setIsShowWaitingDocument,
        isShowSendDocumentModal,
        setIsShowSendDocumentModal
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
