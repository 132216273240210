const authConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_CLIENT_ID_DEV
      : process.env.REACT_APP_CLIENT_ID_PRODUCTION,
  redirect_uri:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_SIGN_IN_REDIRECT_URL_DEV
      : process.env.REACT_APP_SIGN_IN_REDIRECT_URL_PRODUCTION,
  response_type: 'code',
  scope: 'openid profile email emp_code roles edoc.web martech-solution ClientId_api',
  post_logout_redirect_uri:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL_DEV
      : process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL_PRODUCTION,
  automaticSilentRenew: false,
  loadUserInfo: true,
  monitorSession: false
  // stateStore: window.sessionStorage,
};
export default authConfig;
