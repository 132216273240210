import { http } from './http';

const controller = '/edoc/notifications';

export const getNotifications = async (page) => await http.get(`${controller}/get-messages-page`, { params: { page } });

export const getNewestDocumentNotification = async (documentId) =>
  await http.get(`${controller}/get-newest-message`, {
    params: { documentId }
  });

export const readNotification = async (notification) => await http.post(`${controller}/noti-as-read`, notification);

export const readAllNotification = async () => await http.post(`${controller}/noti-all-as-read`);
