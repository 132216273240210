import { useQuery } from '@tanstack/react-query';
import { List, Modal, Typography } from 'antd';
import moment from 'moment';
import { Fragment, useContext } from 'react';
import { getDocumentLog } from 'src/api/documentTimeline.api';
import { DocumentContext } from 'src/context/DocumentContext';
import './index.css';
export default function LogByDocument(props) {
  const { document } = useContext(DocumentContext);
  const styles = {
    text: {
      fontSize: '12px'
    },
    paragraph: {
      fontSize: '12px',
      marginBottom: '0'
    }
  };

  const documentLogQuery = useQuery({
    queryKey: ['documentLog', document?.id],
    queryFn: () => getDocumentLog(document?.id),
    staleTime: 60 * 1000,
    enabled: !!document?.id
  });

  const handleCancel = () => {
    props?.onOpen(false);
  };

  return (
    <Modal
      className='log-by-document-modal'
      open={props?.isOpen}
      loading={documentLogQuery.isFetching}
      title='Log'
      zIndex={1050}
      okText='Đồng ý'
      cancelText='Không'
      onCancel={handleCancel}
      footer={null}
    >
      <List
        style={{
          height: '400px',
          overflow: 'auto'
        }}
        itemLayout='horizontal'
        dataSource={documentLogQuery.data?.data}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Typography.Text style={styles.text}>
                  {item?.createdByInfo
                    ? `${item?.createdByInfo?.fullName} (${item?.createdByInfo?.department})`
                    : document?.informations?.find((x) => !x.isMartech)?.name || 'Đối tác'}
                </Typography.Text>
              }
              description={
                <Fragment>
                  <Typography.Text style={styles.text}>
                    {item?.processor + ' vào lúc ' + moment(item?.created).format('HH:mm DD/MM/YYYY')}
                  </Typography.Text>
                  {item?.comment && (
                    <Typography.Paragraph style={styles.paragraph}>
                      {'Ghi chú: ' + (item?.comment || '')}
                    </Typography.Paragraph>
                  )}
                </Fragment>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  );
}
