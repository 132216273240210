import { useContext } from 'react';
import { AppContext } from 'src/context/AppContext';

export function useCheckRole() {
  const { userRole } = useContext(AppContext);
  const getListRole = (code) => {
    let listRole = [];
    userRole?.forEach((role) => {
      let roleTemplatePermissions = role.roleResources?.find(
        (roleResource) => roleResource.resource.resourceCode === code
      )?.roleResourcePermissions;
      if (roleTemplatePermissions) {
        listRole = [...listRole, ...roleTemplatePermissions];
      }
    });
    return listRole;
  };

  const checkRoleTemplate = (type) => {
    let role;
    let listRole = getListRole('RESRC20230807006');

    switch (type) {
      case 'READ':
        role = listRole?.find((x) => x.permission?.code === 'READ');
        return Boolean(role) ? true : false;
      case 'UPDATE':
        role = listRole?.find((x) => x.permission?.code === 'UPDATE');
        return Boolean(role) ? true : false;
      case 'CREATE':
        role = listRole?.find((x) => x.permission?.code === 'CREATE');
        return Boolean(role) ? true : false;
      case 'DELETE':
        role = listRole?.find((x) => x.permission?.code === 'DELETE');
        return Boolean(role) ? true : false;
      case 'LIMIT_EDITING':
        role = listRole?.find((x) => x.permission?.code === 'LIMIT_EDITING');
        return Boolean(role) ? true : false;
      default:
        return;
    }
  };

  const checkRoleDocument = (type) => {
    let role;
    let listRole = getListRole('RESRC20230808002');

    switch (type) {
      case 'READ':
        role = listRole?.find((x) => x.permission?.code === 'READ');
        return Boolean(role) ? true : false;
      case 'UPDATE':
        role = listRole?.find((x) => x.permission?.code === 'UPDATE');
        return Boolean(role) ? true : false;
      case 'CREATE':
        role = listRole?.find((x) => x.permission?.code === 'CREATE');
        return Boolean(role) ? true : false;
      case 'DELETE':
        role = listRole?.find((x) => x.permission?.code === 'DELETE');
        return Boolean(role) ? true : false;
      case 'APPROVE':
        role = listRole?.find((x) => x.permission?.code === 'DELETE');
        return Boolean(role) ? true : false;
      case 'ASSIGN_OTHER_DEPT':
        role = listRole?.find((x) => x.permission?.code === 'ASSIGN_OTHER_DEPT');
        return Boolean(role) ? true : false;
      case 'ASSIGN':
        role = listRole?.find((x) => x.permission?.code === 'ASSIGN_REVIEW');
        return Boolean(role) ? true : false;
      case 'LIMIT_EDITING':
        role = listRole?.find((x) => x.permission?.code === 'LIMIT_EDITING');
        return Boolean(role) ? true : false;
      case 'CREATE_DOC_FOR_OTHER_DEPT':
        role = listRole?.find((x) => x.permission?.code === 'CREATE_DOC_FOR_OTHER_DEPT');
        return Boolean(role) ? true : false;
      default:
        return;
    }
  };

  const checkRoleApprovalProcess = (type) => {
    let role;
    let listRole = getListRole('RESRC20230808004');

    switch (type) {
      case 'READ':
        role = listRole?.find((x) => x.permission?.code === 'READ');
        return Boolean(role) ? true : false;
      case 'UPDATE':
        role = listRole?.find((x) => x.permission?.code === 'UPDATE');
        return Boolean(role) ? true : false;
      case 'CREATE':
        role = listRole?.find((x) => x.permission?.code === 'CREATE');
        return Boolean(role) ? true : false;
      case 'DELETE':
        role = listRole?.find((x) => x.permission?.code === 'DELETE');
        return Boolean(role) ? true : false;
      default:
        return;
    }
  };

  const checkRoleDepartmentRight = (type) => {
    let role;
    let listRole = getListRole('RESRC20230810001');

    switch (type) {
      case 'READ':
        role = listRole?.find((x) => x.permission?.code === 'READ');
        return Boolean(role) ? true : false;
      case 'UPDATE':
        role = listRole?.find((x) => x.permission?.code === 'UPDATE');
        return Boolean(role) ? true : false;
      case 'CREATE':
        role = listRole?.find((x) => x.permission?.code === 'CREATE');
        return Boolean(role) ? true : false;
      case 'DELETE':
        role = listRole?.find((x) => x.permission?.code === 'DELETE');
        return Boolean(role) ? true : false;
      default:
        return;
    }
  };

  const checkRoleBODRight = (type) => {
    let role;
    let listRole = getListRole('RESRC20230810002');

    switch (type) {
      case 'READ':
        role = listRole?.find((x) => x.permission?.code === 'READ');
        return Boolean(role) ? true : false;
      case 'UPDATE':
        role = listRole?.find((x) => x.permission?.code === 'UPDATE');
        return Boolean(role) ? true : false;
      case 'CREATE':
        role = listRole?.find((x) => x.permission?.code === 'CREATE');
        return Boolean(role) ? true : false;
      case 'DELETE':
        role = listRole?.find((x) => x.permission?.code === 'DELETE');
        return Boolean(role) ? true : false;
      default:
        return;
    }
  };

  const checkRoleStoredDocument = (type) => {
    // let role;
    let listRole = getListRole('02022024_143546');
    return !!listRole?.find((x) => x.permission?.code === type);
  };

  const checkRoleAdmin = () => {
    const roleAdmin = userRole?.find((x) => x.roleCode === 'EDOCADMIN');
    return roleAdmin ? true : false;
  };

  return {
    checkRoleTemplate,
    checkRoleDocument,
    checkRoleApprovalProcess,
    checkRoleDepartmentRight,
    checkRoleBODRight,
    checkRoleStoredDocument,
    checkRoleAdmin
  };
}
