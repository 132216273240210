import { useRoutes } from 'react-router-dom';
import LayoutDashboard from '../layouts/LayoutDashboard';
import LayoutEditor from '../layouts/LayoutEditor';
import SigninOidc from '../pages/SigninOidc';
import SignoutOidc from '../pages/SignoutOidc';
import SignoutCallbackOidc from '../pages/SignoutCallbackOidc';
import { Suspense, lazy } from 'react';
import { DocumentProvider } from 'src/context/DocumentContext';
import urlPath from 'src/constants/urlPath';

//#region Import components
const GuestPage = lazy(() => import('../pages/Guest/GuestPage'));
const CheckQRPage = lazy(() => import('../pages/CheckQR'));
const DocumentationGuidePage = lazy(() => import('../pages/DocumentationGuide/DocumentationGuidePage'));
const DocumentPage = lazy(() => import('../pages/DocumentManage'));

const StoredDocumentPage = lazy(() => import('../pages/StoredDocument/index'));
const TemplatePage = lazy(() => import('../pages/TemplateDocument'));
const ApprovalProcess = lazy(() => import('../pages/ApprovalProcess/index'));
const AddApprovalProcess = lazy(() => import('../pages/AddApprovalProcess'));
const FormMail = lazy(() => import('../pages/FormMail'));
const ReportByYear = lazy(() => import('../pages/Report/ReportByYear'));
const ReportByCategory = lazy(() => import('../pages/Report/ReportByCategory'));

const AddAttachment = lazy(() => import('../pages/Attachment'));
const DepartmentRights = lazy(() => import('../pages/DepartmentRights'));
const BODRightsPage = lazy(() => import('../pages/BODRights/index.jsx'));
const Representative = lazy(() => import('../pages/Representative'));
const RepresentativeAdd = lazy(() => import('../pages/Representative/RepresentativeAdd.jsx'));

const AddBodRights = lazy(() => import('../pages/BODRights/AddBodRights.jsx'));
const NoPermission = lazy(() => import('../pages/NoPermission/NoPermission'));
const NavigationPage = lazy(() => import('../pages/NavigationPage/NavigationPage'));
const NotFound = lazy(() => import('../pages/ErrorPage/NotFound'));

const CreateTemplatePage = lazy(() => import('../pages/TemplateEdit'));
const UpdateTemplatePage = lazy(() => import('../pages/TemplateEdit'));
const CreateDocumentPage = lazy(() => import('../pages/DocumentEdit/DocumentFile'));
const EditDocumentPage = lazy(() => import('../pages/DocumentEdit/DocumentFile'));
const CategoryPage = lazy(() => import('../pages/Category/ListCategory'));
const HandoverPage = lazy(() => import('../pages/Handover'));
//#endregionimport CheckQRPage from './../pages/CheckQR/index';

export default function useRouteElements() {
  const routeElements = useRoutes([
    {
      path: urlPath.home.location,

      element: <NavigationPage />
    },
    {
      path: '/guest/:state',

      element: <GuestPage />
    },
    {
      path: urlPath.documents.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <DocumentPage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.waittingDocument.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            {/* <WaitingDocumentPage /> */}
            <DocumentPage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.handledDocument.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            {/* <HandledDocumentPage /> */}
            <DocumentPage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.myDocument.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            {/* <MyDocumentPage /> */}
            <DocumentPage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.storedDocument.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <StoredDocumentPage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.template.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <TemplatePage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.category.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <CategoryPage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.departmentRights.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <DepartmentRights />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.bodRights.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <BODRightsPage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: `${urlPath.bodRightUpdate.location}/:id`,

      element: (
        <LayoutDashboard>
          <Suspense>
            <AddBodRights />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.bodRightAdd.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <AddBodRights />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.approvalProcess.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <ApprovalProcess />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: `${urlPath.approvalProcessUpdate.location}/:id`,

      element: (
        <LayoutDashboard>
          <Suspense>
            <AddApprovalProcess />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.approvalProcessAdd.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <AddApprovalProcess />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.formMail.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <FormMail />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.documentAttach.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <AddAttachment />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.reportsByYear.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <ReportByYear />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.reportsByCategory.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <ReportByCategory />
          </Suspense>
        </LayoutDashboard>
      )
    },
    // {
    //   path: "/reports-by-department",
    //
    //   element: (
    //     <LayoutDashboard>
    //       <Suspense>{/* <ReportByDepartment /> */}</Suspense>
    //     </LayoutDashboard>
    //   ),
    // },
    // {
    //   path: "/reports-dept-by-category",
    //
    //   element: (
    //     <LayoutDashboard>
    //       <Suspense>
    //         <ReportDepartmentByCategory />
    //       </Suspense>
    //     </LayoutDashboard>
    //   ),
    // },
    {
      path: urlPath.documentationGuide.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <DocumentationGuidePage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.representative.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <Representative />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: urlPath.representativeAdd.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <RepresentativeAdd />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: `${urlPath.representativeUpdate.location}/:id`,

      element: (
        <LayoutDashboard>
          <Suspense>
            <RepresentativeAdd />
          </Suspense>
        </LayoutDashboard>
      )
    },

    {
      path: urlPath.createTemplate.location,

      element: (
        <LayoutEditor>
          <Suspense>
            <DocumentProvider>
              <CreateTemplatePage />
            </DocumentProvider>
          </Suspense>
        </LayoutEditor>
      )
    },
    {
      path: urlPath.updateTemplate.location,

      element: (
        <LayoutEditor>
          <Suspense>
            <DocumentProvider>
              <UpdateTemplatePage />
            </DocumentProvider>
          </Suspense>
        </LayoutEditor>
      )
    },
    {
      path: urlPath.createDocument.location,

      element: (
        <LayoutEditor>
          <Suspense>
            <DocumentProvider>
              <CreateDocumentPage />
            </DocumentProvider>
          </Suspense>
        </LayoutEditor>
      )
    },
    {
      path: urlPath.updateDocument.location,

      element: (
        <LayoutEditor>
          <Suspense>
            <DocumentProvider>
              <EditDocumentPage />
            </DocumentProvider>
          </Suspense>
        </LayoutEditor>
      )
    },
    {
      path: urlPath.handover.location,

      element: (
        <LayoutDashboard>
          <Suspense>
            <HandoverPage />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: '/scan-document/:state',

      element: <CheckQRPage />
    },

    //#region Signin
    {
      path: '/signin-oidc',
      element: <SigninOidc />
    },
    {
      path: '/signout-callback-oidc',
      element: <SignoutCallbackOidc />
    },
    {
      path: '/signout-oidc',
      element: <SignoutOidc />
    },
    {
      path: '/nopermissions',

      element: (
        <LayoutDashboard>
          <Suspense>
            <NoPermission />
          </Suspense>
        </LayoutDashboard>
      )
    },
    {
      path: '*',
      element: <NotFound />
    }
    //#endregion
  ]);
  return { routeElements };
}
