import { UserManager } from 'oidc-client';
import { clearLS } from '../Authentication/auth';
import authConfig from '../Authentication/authConfig';

const userManager = new UserManager(authConfig);

export function signinRedirect(url) {
  userManager.clearStaleState();
  return userManager.signinRedirect(url && { state: { url: url } });
}
export function signinCallback() {
  return userManager.signinCallback();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function renewToken() {
  return userManager.signinSilent();
}

export async function signoutRedirect() {
  const token = JSON.parse(localStorage.getItem('profile'))?.id_token;

  userManager.clearStaleState();
  userManager.removeUser();
  clearLS();
  localStorage.removeItem('locationState');
  return userManager.signoutRedirect({
    id_token_hint: token
  });
}

export async function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  clearLS();
  localStorage.removeItem('locationState');
  return userManager.signoutRedirectCallback();
}

export function getUser() {
  const user = userManager.getUser();
  return user;
}

export async function getToken() {
  const user = await getUser();
  return user && user.access_token;
}
