import { Button } from 'antd';
import React from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import ChangePasswordModal from '../Modal/ChangePasswordModal';

export default function ChangePasswordButton(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const onClick = () => {
    setIsOpen(true);
  };
  return (
    <Fragment>
      <Button style={{ width: '100%', textAlign: 'left' }} type='text' icon={<SyncOutlined />} onClick={onClick}>
        {t('header.user.changePassword')}
      </Button>
      {isOpen && <ChangePasswordModal onOpen={setIsOpen} isOpen={isOpen} {...props} />}
    </Fragment>
  );
}
