import { useEffect, useState } from 'react';
import DashboardTopbar from 'src/components/Header/MainHeader';
import CategoryIcon from 'src/style/image/category.png';
import ContractIcon from 'src/style/image/contract.png';
import MailIcon from 'src/style/image/email.png';
import HandledIcon from 'src/style/image/handled.png';
import OwnerIcon from 'src/style/image/owner.png';
import ApprovalIcon from 'src/style/image/planning.png';
import StorageIcon from 'src/style/image/storage.png';
import TemplateIcon from 'src/style/image/template.png';
import WaitingIcon from 'src/style/image/waiting.png';
import ChangeIcon from 'src/style/image/change.png';

import { BarChartOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Avatar, Flex, Layout, Menu, Space, Spin } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertGlobal } from 'src/components/Alert';
import Breadcrumb from 'src/components/Breadcrumb/index';
import AddPrincipalContractButton from 'src/components/Button/AddPrincipalContractButton';
import Footer from 'src/components/Footer/Footer';
import urlPath from 'src/constants/urlPath';
import { AppContext } from 'src/context/AppContext';
import LogoMartechCollapse from 'src/style/image/Logo_Martech_1.jpg';
import LogoMartechFull from 'src/style/image/Logo_Martech_2.png';
import DepartmentRightIcon from 'src/style/image/key.png';
import RepresentativeIcon from 'src/style/image/signature.png';
import { getLastItem } from 'src/utils/utils';
import { useCheckRole } from '../hook/useCheckRoles';
import './LayoutDashboard.css';
import ErrorNoRole from 'src/components/ErrorMessage/ErrorNoRole';
const { Header, Content, Sider } = Layout;

const LayoutDashboard = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { checkRoleStoredDocument, checkRoleAdmin } = useCheckRole();
  const { setSelectedMenuItem, hasRole } = useContext(AppContext);
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type
    };
  }

  const items = [
    getItem(
      t('sidebar.forUser'),
      'sub1',
      null,
      [
        getItem(
          getLastItem(urlPath.relativeDocument.path),
          urlPath.relativeDocument.location,
          <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={ContractIcon} />
        ),
        getItem(
          getLastItem(urlPath.waittingDocument.path),
          urlPath.waittingDocument.location,
          <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={WaitingIcon} />
        ),
        getItem(
          getLastItem(urlPath.handledDocument.path),
          urlPath.handledDocument.location,
          <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={HandledIcon} />
        ),
        getItem(
          getLastItem(urlPath.myDocument.path),
          urlPath.myDocument.location,
          <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={OwnerIcon} />
        ),
        checkRoleStoredDocument('READ') &&
          getItem(
            getLastItem(urlPath.storedDocument.path),
            urlPath.storedDocument.location,
            <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={StorageIcon} />
          )
      ],
      'group'
    ),
    checkRoleAdmin() &&
      getItem(
        t('sidebar.forAdministrator'),
        'sub2',
        null,
        [
          getItem(
            getLastItem(urlPath.template.path),
            urlPath.template.location,
            <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={TemplateIcon} />
          ),
          getItem(
            getLastItem(urlPath.category.path),
            urlPath.category.location,
            <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={CategoryIcon} />
          ),
          getItem(
            getLastItem(urlPath.departmentRights.path),
            urlPath.departmentRights.location,
            <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={DepartmentRightIcon} />
          ),
          getItem(
            getLastItem(urlPath.bodRights.path),
            urlPath.bodRights.location,
            <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={DepartmentRightIcon} />
          ),
          getItem(
            getLastItem(urlPath.representative.path),
            urlPath.representative.location,
            <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={RepresentativeIcon} />
          ),
          // getItem("Assignment", "3", <FileAddOutlined />, null, "/assignment"),
          // getItem('Report', '3', <BarChartOutlined />),
          getItem(
            getLastItem(urlPath.approvalProcess.path),
            urlPath.approvalProcess.location,
            <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={ApprovalIcon} />
          ),
          getItem(
            getLastItem(urlPath.formMail.path),
            urlPath.formMail.location,
            <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={MailIcon} />
          ),
          getItem(
            getLastItem(urlPath.handover.path),
            urlPath.handover.location,
            <Avatar shape='square' style={{ width: '24px', height: '24px' }} src={ChangeIcon} />
          )
        ],
        'group'
      ),

    getItem(
      t('sidebar.support'),
      'sub3',
      null,
      [
        getItem(
          getLastItem(urlPath.documentationGuide.path),
          urlPath.documentationGuide.location,
          <QuestionCircleOutlined />
        )
      ],
      'group'
    ),
    checkRoleAdmin() &&
      getItem(
        t('sidebar.report'),
        'sub4',
        null,
        [
          getItem(getLastItem(urlPath.reportsByYear.path), urlPath.reportsByYear.location, <BarChartOutlined />),
          getItem(getLastItem(urlPath.reportsByCategory.path), urlPath.reportsByCategory.location, <BarChartOutlined />)
          // getItem(
          //   t("sidebar.reportDeptByCategory"),
          //   menuResource.REPORT_DEPT_CATEGORY,
          //   <BarChartOutlined />
          // ),
        ],
        'group'
      )
  ];

  useEffect(() => {
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundColor: '#fff'
      }}
      className='layout-main'
    >
      <Header
        style={{
          padding: 0,
          background: '#001529'
        }}
        theme='dark'
      >
        <DashboardTopbar />
      </Header>
      {hasRole === undefined ? (
        <Space
          direction='vertical'
          style={{
            width: '100vw',
            textAlign: 'center',
            paddingTop: '50px'
          }}
        >
          <Spin size='large'></Spin>
        </Space>
      ) : hasRole === false ? (
        <ErrorNoRole />
      ) : (
        <Layout className='site-layout'>
          <Sider
            width={250}
            collapsible
            theme='light'
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <img
              src={collapsed ? LogoMartechCollapse : LogoMartechFull}
              alt=''
              className='sidebar__img'
              style={{
                width: '90%',
                padding: ' 20px'
              }}
            />
            <Flex justify='center'>
              <AddPrincipalContractButton collapsed={collapsed} />
            </Flex>
            {/* <AddNewContractButton collapsed={collapsed} /> */}
            <Menu
              theme='light'
              defaultSelectedKeys={[selectedKey]}
              mode='inline'
              style={{
                borderInlineEnd: 'none',
                maxHeight: 'calc(100vh - 400px)',
                overflowY: 'auto'
              }}
              items={items}
              onClick={({ key }) => {
                setSelectedMenuItem(key);
                navigate(key);
              }}
            />
          </Sider>
          <Content>
            <Flex vertical>
              <Breadcrumb />
              <AlertGlobal />
              <div className='layout-main-content'>{children}</div>
              <Footer />
            </Flex>
          </Content>
        </Layout>
      )}
    </Layout>
  );
};

export default LayoutDashboard;
