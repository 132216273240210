import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import React from 'react';
import { getCategories } from 'src/api/category.api';

export default function CategorySelect({
  placeholder,
  style,
  className,
  value,
  onChange,
  isShowAll,
  disabled,
  showSearch
}) {
  const categoryQuery = useQuery({
    queryKey: ['categoryDropdown'],
    queryFn: () => getCategories(),
    staleTime: 60 * 1000 * 60
  });

  return (
    <Select
      className={`select-custom ${className}`}
      placeholder={placeholder ?? 'Loại văn bản'}
      allowClear
      value={value ? Number(value) : undefined}
      disabled={disabled}
      style={{ width: '300px', ...style }}
      onChange={onChange}
      loading={categoryQuery.isFetching}
      options={categoryQuery.data?.data?.map((item) => ({
        value: item.id,
        label: item.name
      }))}
      showSearch
      optionFilterProp='children'
      filterOption={(input, option) => (option?.label ?? '').includes(input)}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
      }
    />
  );
}
