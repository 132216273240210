import { Button } from 'antd';
import React, { Fragment, useState } from 'react';
import LogByDocument from '../Modal/LogByDocument/LogByDocument';

export default function OpenLogByDocument(props) {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setIsOpen(true);
  };
  return (
    <Fragment>
      <Button
        ariaLabel={props?.ariaLabel}
        style={props?.style}
        type={props?.type}
        loading={props?.loading}
        onClick={onClick}
      >
        {props?.text}
      </Button>
      {isOpen && <LogByDocument onOpen={setIsOpen} isOpen={isOpen} documentId={props?.documentId} />}
    </Fragment>
  );
}
