import { useQuery } from '@tanstack/react-query';
import { Alert, Space, Typography } from 'antd';
import Marquee from 'react-fast-marquee';
import { getCountAlmostExpired } from 'src/api/document.api';
import PrincipleExpiringButton from 'src/components/Button/PrincipleExpiringButton';
import './AlertGlobal.css';
const { Text } = Typography;
export default function AlertGlobal() {
  const isPrinciple = true;
  const { data } = useQuery({
    queryKey: ['countAlmostExpired'],
    queryFn: () => getCountAlmostExpired(isPrinciple),
    staleTime: 60 * 1000 * 60,
    keepPreviousData: true
  });

  return (
    <div className={`alert-global ${data?.data > 0 ? 'open' : ''}`}>
      {data?.data > 0 && (
        <Alert
          message={
            <Marquee pauseOnHover gradient={false} speed={80}>
              <Text
                className='alert-text'
                strong
              >{`Có ${data?.data} hợp đồng nguyên tắc sắp hết hiệu lực, vui lòng làm mới lại!`}</Text>
            </Marquee>
          }
          banner
          type='error'
          showIcon={false}
          action={
            <Space>
              <PrincipleExpiringButton type='link' size='small' text='Xem ngay' />
            </Space>
          }
          closable
        />
      )}
    </div>
  );
}
