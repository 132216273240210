import { LoginOutlined, TranslationOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Popover, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { signoutRedirect } from 'src/utils/auth';
import ChangePasswordButton from 'src/components/Button/ChangePasswordButton';
import Notification from 'src/components/Notification';
import './MainHeader.css';
import { useContext } from 'react';
import { AppContext } from 'src/context/AppContext';

const DashboardTopbar = () => {
  const { user } = useContext(AppContext);
  const profile = JSON.parse(localStorage.getItem('profile'));
  const handleSignout = () => {
    signoutRedirect();
  };
  const language = localStorage.getItem('language') || 'vi';
  const { t, i18n } = useTranslation();
  const handleChange = () => {
    i18n.changeLanguage(language === 'vi' ? 'en' : 'vi');
    localStorage.setItem('language', i18n.language);
  };
  const contentPopOver = () => {
    return (
      <div className='content-panel'>
        <Button
          style={{ width: '100%', textAlign: 'left' }}
          type='text'
          icon={<TranslationOutlined />}
          onClick={handleChange}
        >
          {t('langDesc')}
        </Button>
        <ChangePasswordButton />
        <Button
          style={{ width: '100%', textAlign: 'left' }}
          type='text'
          icon={<LoginOutlined />}
          onClick={handleSignout}
        >
          {t('header.user.logout')}
        </Button>
      </div>
    );
  };

  return (
    <Row justify='space-between' className='header-wrapper'>
      <Col span={18} className='header-title'>
        <Button type='text' style={{ color: '#fff', fontWeight: '500' }}>
          E-Document
        </Button>
      </Col>
      <Col span={6} className='header-user'>
        {/* {!isProduction && <Tag color="green">Server test</Tag>} */}

        {profile?.profile?.emp_code && !profile?.profile?.company_code && <Notification />}
        <Popover content={contentPopOver} placement='bottom' arrow={true} trigger='click'>
          <Button
            type='text'
            style={{ color: '#fff', fontWeight: '500', margin: '0' }}
            icon={
              <Avatar
                style={{
                  backgroundColor: '#2DB7F5',
                  fontSize: '20px'
                }}
                icon={<UserOutlined />}
              />
            }
          >
            {user?.fullName ?? 'Đang lấy dữ liệu'}{' '}
          </Button>
        </Popover>
      </Col>
    </Row>
  );
};

export default DashboardTopbar;
