export const setAccessTokenToLS = (access_token) => {
  localStorage.setItem('access_token', access_token);
};

export const getAccessTokenFromLS = () => localStorage.getItem('access_token') || '';

export const clearLS = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('profile');
};

export const getProfileFromLS = () => {
  const result = localStorage.getItem('profile');
  return result ? JSON.parse(result) : null;
};

export const setProfileToLS = (profile) => {
  setAccessTokenToLS(profile.access_token);
  localStorage.setItem('profile', JSON.stringify(profile));
};
