import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { signoutRedirect } from 'src/utils/auth';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onLogout = () => {
    signoutRedirect();
  };
  const onBack = () => {
    navigate(-1);
  };
  return (
    <Result
      status='404'
      title='404'
      subTitle='Sorry, the page you visited does not exist.'
      extra={
        <Fragment>
          <Button type='primary' onClick={onBack}>
            {t('notFoundPage.backBtn')}
          </Button>
          <Button type='primary' onClick={onLogout}>
            {t('notFoundPage.logoutBtn')}
          </Button>
        </Fragment>
      }
    />
  );
}
