import { BellOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Col, List, Popover, Row, Typography } from 'antd';
import moment from 'moment';
import 'moment/locale/vi'; // Import ngôn ngữ Tiếng Việt từ Moment.js
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { editorPageType, notificationMessageTypes } from 'src/constant';
import ApprovedIcon from 'src/style/image/approvedIcon.svg';
import AssignIcon from 'src/style/image/assignIcon.svg';
import CommentIcon from 'src/style/image/commentIcon.svg';
import RejectIcon from 'src/style/image/rejectIcon.svg';
import NormalIcon from 'src/style/image/reviewIcon.svg';

import { useMutation, useQuery } from '@tanstack/react-query';
import { getNotifications, readAllNotification, readNotification } from 'src/api/notification.api';
import urlPath from 'src/constants/urlPath';
import { AppContext } from 'src/context/AppContext';
import './Notification.css';

export default function Notification() {
  moment.locale('vi');
  moment().format('LLLL');
  const [notifications, setNotifications] = useState([]);
  const [counter, setCounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const navigate = useNavigate();
  const { setAnchorChild, notifyMessage } = useContext(AppContext);
  const { t } = useTranslation();

  const style = {
    list: {
      width: '434px',
      maxHeight: '50vh',
      overflowY: 'auto'
    }
  };

  const notificationQuery = useQuery({
    queryKey: ['notifications', currentPage],
    queryFn: () => getNotifications(currentPage),
    staleTime: 60 * 1000 * 60,
    keepPreviousData: true,
    onSuccess: (response) => {
      setNotifications((prev) => [...prev, ...response?.data?.message]);
      setTotalPage(response?.data?.total);
      setCounter(response?.data?.unRead);
    }
  });

  const readNotificationMutation = useMutation({
    mutationFn: (listNoti) => readNotification(listNoti),
    onSuccess: (response, rq) => {
      setNotifications((prev) =>
        prev.map((item) => {
          if (rq?.[0] === item.id) {
            return {
              ...item,
              isRead: true
            };
          } else {
            return item;
          }
        })
      );
      counter > 0 && setCounter(counter - 1);
    }
  });

  const readAllNotificationMutation = useMutation({
    mutationFn: () => readAllNotification(),
    onSuccess: () => {
      setNotifications((prev) => prev?.map((x) => ({ ...x, isRead: true })));
      setCounter(0);
    }
  });

  const onClick = (item) => {
    if (item?.type === 'COMMENTAPPENDIX' || item?.type === 'COMMENTDOC') {
      setAnchorChild(item.documentChildId);
    } else {
      updateNotiAsRead([item?.id]);
      navigate(urlPath.updateDocument.location, {
        state: {
          docId: item?.documentId,
          categoryId: null,
          editorPageType: editorPageType.UPDATE_DOCUMENT
        }
      });
    }
  };

  const updateNotiAsRead = (listNoti) => {
    readNotificationMutation.mutate(listNoti);
  };

  const handleReadAllNotification = () => {
    readAllNotificationMutation.mutate();
  };

  const getAvatarComment = (type) => {
    switch (type) {
      case notificationMessageTypes.APPROVE:
        return ApprovedIcon;
      case notificationMessageTypes.COMMENTDOC:
        return CommentIcon;
      case notificationMessageTypes.COMMENTAPPENDIX:
        return CommentIcon;
      case notificationMessageTypes.REJECT:
        return RejectIcon;
      case notificationMessageTypes.ASSIGN:
        return AssignIcon;
      case notificationMessageTypes.NORMAL:
        return NormalIcon;
      default:
        return;
    }
  };
  useEffect(() => {
    if (notifyMessage) {
      setNotifications((prev) => [notifyMessage, ...prev]);
      setCounter((prev) => prev + 1);
    }
  }, [notifyMessage]);

  // useEffect(() => {
  //   if (notificationQuery.data) {
  //     setNotifications((prev) => [
  //       ...prev,
  //       ...notificationQuery.data?.data?.message,
  //     ]);
  //     setTotalPage(notificationQuery.data?.data?.total);
  //     setCounter(notificationQuery.data?.data?.unRead);
  //   }
  // }, [notificationQuery.data]);

  return (
    <Popover
      content={
        <List
          className='loadmore-list'
          // loading={loading}
          itemLayout='horizontal'
          loadMore={
            <div
              style={{
                textAlign: 'center',
                marginTop: 4,
                marginBottom: 0,
                height: 32,
                lineHeight: '32px'
              }}
            >
              <Button
                type='primary'
                shape='round'
                loading={notificationQuery.isFetching}
                disabled={currentPage * 5 >= totalPage}
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                {t('header.bell.more')}
              </Button>
            </div>
          }
          dataSource={notifications}
          style={style.list}
          renderItem={(item) => (
            <List.Item
              className='notification-item'
              // actions={[
              //   !item?.isRead && (
              //     <Badge
              //       dot={!item.isRead}
              //       color="cyan"
              //       dotSize="40"
              //       offset={[5, 0]}
              //     ></Badge>
              //   ),
              // ]}
            >
              <List.Item.Meta
                avatar={<Avatar size={40} src={getAvatarComment(item.type)} />}
                title={
                  <Typography.Text className='notification-content' onClick={() => onClick(item)}>
                    {item.messageContent}
                  </Typography.Text>
                }
                description={
                  <Row justify='space-between' style={{ paddingRight: '10px' }}>
                    <Col>
                      <Typography.Text strong className='notification-date'>
                        {moment(item.created).startOf('hour').fromNow()}
                      </Typography.Text>
                      {!item?.isRead && <Badge dot={!item.isRead} color='cyan' dotSize='80' offset={[5, 0]}></Badge>}
                    </Col>
                    <Col>
                      {!item.isRead && (
                        <span className='mark-read-span' onClick={() => updateNotiAsRead([item.id])}>
                          {t('header.bell.markAsRead')}
                        </span>
                      )}
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      }
      title={
        <Row justify='space-between' style={{ padding: '0 16px' }}>
          <Col>
            <Typography.Title level={5}>{`${t('header.bell.notification')} (${counter})`}</Typography.Title>
          </Col>
          <Col>
            <Typography.Link
              disabled={readAllNotificationMutation.isLoading}
              onClick={() => handleReadAllNotification()}
            >
              {t('header.bell.markAsReadAll')}
            </Typography.Link>
          </Col>
        </Row>
      }
      arrow={true}
      trigger='click'
      style={{ padding: '0px' }}
    >
      <Badge
        count={counter}
        offset={[0, 5]}
        overflowCount={10}
        // size="small"
        className='notification-btn'
      >
        <Button
          type='text'
          style={{ color: '#fff', fontWeight: '500' }}
          icon={
            <Avatar
              style={{
                backgroundColor: '#2DB7F5'
              }}
              icon={<BellOutlined />}
            />
          }
        ></Button>
      </Badge>
    </Popover>
  );
}
