import { http } from './http';

const controller = '/edoc/documents';

export const getDocument = async (id) => await http.get(`${controller}/get-document/${id}`);

export const getInfoDocumentByQr = async (documentId) =>
  await http.get(`${controller}/qr-check`, { params: { documentId } });

export const getRootDocument = async (numberDoc) =>
  await http.get(`${controller}/get-root-document`, { params: { numberDoc } });

export const addDocument = async (document) => await http.post(`${controller}`, document);

export const updateDocument = async (document) => await http.put(`${controller}/${document.id}`, document);

export const closedDocument = async (closeRq) => await http.put(`${controller}/closed-document`, closeRq);

export const addAppendixToDocument = async (document, documentId) =>
  await http.post(`${controller}/add-appendix-to-document`, document, {
    params: { documentId }
  });

export const getDocumentByPrinciple = async (params) =>
  await http.get(`${controller}/document-by-principle`, {
    params: params
  });
export const getRelativeDocuments = async (params) =>
  await http.get(`${controller}`, {
    params: params
  });
export const getWaitingDocuments = async (params) =>
  await http.get(`${controller}/waiting-action`, {
    params: params
  });

export const getSolvedDocuments = async (params) =>
  await http.get(`${controller}/my-completed`, {
    params: params
  });
export const getMyDocuments = async (params) =>
  await http.get(`${controller}/my-document`, {
    params: params
  });
export const getStoredDocuments = async (params) =>
  await http.get(`${controller}/closes`, {
    params: params
  });
export const getWaitingUploadScanDocuments = async (params) =>
  await http.get(`${controller}/waiting-upload-scan`, {
    params: params
  });
export const getAlmostExpiredDocuments = async (params) =>
  await http.get(`${controller}/documents-almost-expired`, {
    params: params
  });
export const getPrincipleDocument = async (partnerCode) =>
  await http.get(`${controller}/document-principles-by-partner-code`, {
    params: { partnerCode }
  });

export const getCountAlmostExpired = async (isPrinciple) =>
  await http.get(`${controller}/count-almost-expired`, {
    params: { isPrinciple }
  });

export const getDocNumber = async (categoryId) =>
  await http.get(`${controller}/get-doc-number`, { params: { categoryId } });

export const sendMailNotifyBodClosing = async (sendMailRq) =>
  await http.post(`${controller}/bod-send-mail`, sendMailRq);

export const legalSendMail = async (sendMailRq) => await http.post(`${controller}/legal-send-mail`, sendMailRq);

export const sendMailLiquid = async (sendMailRq) => await http.post(`${controller}/send-mail-liquid`, sendMailRq);

export const sendDocument = async (approvalRq) => await http.post(`${controller}/send-document`, approvalRq);

export const deleteDocument = async (documentId) => await http.delete(`${controller}/${documentId} `);

// History document
export const getHistoryOfDocumentTitle = async (documentTitleId) =>
  await http.get(`${controller}/history-document-title`, {
    params: { documentTitleId }
  });
export const getHistoryOfDocumentChild = async (documentChildId, isGetAll) =>
  await http.get(`${controller}/history-document-child`, {
    params: { documentChildId, isGetAll }
  });

// Storage location
export const updateStorageLocation = async (documentId, storageLocation) =>
  await http.put(`${controller}/update-storage-location`, {
    documentId,
    storageLocation
  });

// Partner Mail
export const resendPartnerMail = async (approvalRq) => await http.put(`${controller}/resend-mail`, approvalRq);

// Handover
export const addHandover = async (handoverRq) => await http.put(`${controller}/hand-over`, handoverRq);
export const getHandovers = async (handOverFrom, handOverTo, documentId) =>
  await http.get(`${controller}/hand-over-logs`, { params: { documentId, handOverFrom, handOverTo } });
export const getDocumentHandovers = async (documentId) =>
  await http.get(`${controller}/document-hand-over-logs`, { params: { documentId } });
