import { Layout } from 'antd';
import { useContext } from 'react';
import { AppContext } from 'src/context/AppContext';
import NotFound from '../pages/ErrorPage/NotFound';
const { Content } = Layout;
const LayoutEditor = ({ children }) => {
  const { user, userRole } = useContext(AppContext);
  if (!userRole && !user?.isPartner) {
    return (
      <Layout
        style={{
          minHeight: '100vh',
          backgroundColor: '#fff'
        }}
      >
        <NotFound />
      </Layout>
    );
  } else {
    return (
      <Layout
        style={{
          minHeight: '100vh',
          backgroundColor: '#fff'
        }}
      >
        <Content>{children}</Content>
      </Layout>
      // <Layout
      //   style={{
      //     minHeight: "100vh",
      //     backgroundColor: "#fff",
      //   }}
      // >
      //   <LeftSidebarDocument />
      //   <Layout hasSider>
      //     <Content>{children}</Content>
      //   </Layout>
      // </Layout>
    );
  }
};

export default LayoutEditor;
