import { useMutation } from '@tanstack/react-query';
import { Button, Col, Divider, Flex, Form, Input, Modal, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { changePassword } from 'src/api/userLogin.api';
import { showNotification } from 'src/utils/notification';
import './ChangePasswordModal.css';
const { Text } = Typography;

export default function ChangePasswordModal(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const profile = JSON.parse(localStorage.getItem('profile'))?.profile;

  const changePasswordMutation = useMutation({
    mutationFn: (rq) => changePassword(rq),
    onSuccess: () => showNotification('Đổi mật khẩu thành công!', 'success')
  });

  const handleCancel = () => {
    props?.onOpen(false);
  };

  const checkIsValid = (data) => {
    let isValid = true;
    let message = '';
    if (data?.newPassword === data?.retypePassword) {
      const isNonWhiteSpace = /^\S*$/;
      if (!isNonWhiteSpace.test(data?.newPassword)) {
        message = 'Mật khẩu không được chưa khoảng trắng';
        isValid = false;
      }

      const isContainsUppercase = /^(?=.*[A-Z]).*$/;
      if (!isContainsUppercase.test(data?.newPassword)) {
        message = 'Mật khẩu phải có ít nhất 1 chữ hoa';
        isValid = false;
      }

      const isContainsLowercase = /^(?=.*[a-z]).*$/;
      if (!isContainsLowercase.test(data?.newPassword)) {
        message = 'Mật khẩu phải có ít nhất 1 chữ thường';
        isValid = false;
      }

      const isContainsNumber = /^(?=.*[0-9]).*$/;
      if (!isContainsNumber.test(data?.newPassword)) {
        message = 'Mật khẩu phải có ít nhất 1 chữ số';
        isValid = false;
      }

      const isContainsSymbol = /^(?=.*[.!@#$%^&*]).*$/;
      if (!isContainsSymbol.test(data?.newPassword)) {
        message = 'Mật khẩu phải có ít nhất 1 ký tự đặc biệt';
        isValid = false;
      }

      const isValidLength = /^.{8,16}$/;
      if (!isValidLength.test(data?.newPassword)) {
        message = 'Mật khẩu phải có độ dài từ 8 - 16 ký tự';
        isValid = false;
      }
    } else {
      showNotification('Nhập lại mật khẩu không trùng khớp!', 'warning');
    }
    if (!isValid) {
      showNotification(message, 'warning');
    }
    return isValid;
  };

  const handleSubmit = (data) => {
    if (checkIsValid(data)) {
      changePasswordMutation.mutate({
        userId: profile?.sub,
        oldPassword: data?.oldPassword,
        password: data?.newPassword,
        confirmPassword: data?.retypePassword
      });
    }
  };

  return (
    <Modal
      className='change-password-modal'
      open={props?.isOpen}
      title={t('changePasswordModal.heading')}
      zIndex={1050}
      okText='Đồng ý'
      cancelText='Không'
      onCancel={handleCancel}
      footer={null}
    >
      <Row gutter={[20]}>
        <Col span={13}>
          <Form
            form={form}
            layout='horizontal'
            labelCol={{ flex: '140px', offset: 0 }}
            labelAlign='left'
            onFinish={handleSubmit}
          >
            <Form.Item
              label={t('changePasswordModal.label.currentPassword')}
              name='oldPassword'
              style={{ marginBottom: '10px' }}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu hiện tại'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={t('changePasswordModal.label.newPassword')}
              name='newPassword'
              style={{ marginBottom: '10px' }}
              rules={[
                {
                  type: 'password',
                  required: true,
                  message: 'Vui lòng nhập mật khẩu mới'
                }
              ]}
            >
              <Input.Password disabled={changePasswordMutation.isLoading} />
            </Form.Item>
            <Form.Item
              label={t('changePasswordModal.label.retype')}
              name='retypePassword'
              style={{ marginBottom: '10px' }}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng xác nhận lại mật khẩu mới'
                }
              ]}
            >
              <Input.Password disabled={changePasswordMutation.isLoading} />
            </Form.Item>

            <Row justify='end'>
              <Col>
                <Button type='primary' htmlType='submit' loading={changePasswordMutation.isLoading}>
                  {t('changePasswordModal.confirmBtn')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={1}>
          <Divider type='vertical' style={{ height: '100%' }} />
        </Col>

        <Col span={10}>
          <Flex vertical>
            <Text strong>Quy tắc đặt mật khẩu:</Text>
            <Text>- Không được chưa khoảng trắng</Text>
            <Text>- Có ít nhất 1 chữ hoa</Text>
            <Text>- Có ít nhất 1 chữ thường</Text>
            <Text>- Có ít nhất 1 chữ số</Text>
            <Text>- Có ít nhất 1 ký tự đặc biệt</Text>
            <Text>- Có độ dài từ 8 - 16 ký tự</Text>
          </Flex>
        </Col>
      </Row>
    </Modal>
  );
}
