import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import React, { useEffect } from 'react';
import './App.css';
import { getAccessTokenFromLS } from './Authentication/auth';
import useRouteElements from './hook/useRouteElements';

import { useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { urlSocket } from './constants/url';
import i18n from './translation/i18n';
import { showNotification } from './utils/notification';
import { useContext } from 'react';
import { AppContext } from './context/AppContext';

function App() {
  const { setNotifyMessage } = useContext(AppContext);
  const [connection, setConnection] = useState(undefined);
  const { routeElements } = useRouteElements();

  useEffect(() => {
    const accessToken = getAccessTokenFromLS();
    if (accessToken) {
      const connect = new HubConnectionBuilder()
        .withUrl(urlSocket + '/hubs/notifications', {
          accessTokenFactory: () => accessToken
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.None)
        .build();
      setConnection(connect);
    }
  }, []);

  useEffect(() => {
    if (connection) {
      if (connection.state === 'Disconnected') {
        connection
          .start()
          .then(() => {
            connection.on('ReceiveMessage', (message) => {
              console.log({ message });
              setNotifyMessage(message);
              showNotification(message.messageContent);
            });
          })
          .catch((error) => console.log(error));
      }
    }
  }, [connection, setNotifyMessage]);

  return <I18nextProvider i18n={i18n}>{routeElements}</I18nextProvider>;
}

export default App;
