import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider, theme } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { AppProvider } from './context/AppContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0
    }
  }
});
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <ConfigProvider
          theme={{
            algorithm: [theme.compactAlgorithm],
            token: {
              colorPrimary: '#2DB7F5',
              Table: {
                fontSize: '13px'
              },
              Menu: {
                fontSize: '13px'
              },
              Input: {
                padding: '4px'
              }
            }
          }}
        >
          <App />
        </ConfigProvider>
      </AppProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

serviceWorkerRegistration.register();
