import { Space, Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import urlPath from 'src/constants/urlPath';
import { signinRedirectCallback } from 'src/utils/auth';
import { setProfileToLS } from '../../Authentication/auth';
export default function SigninOidc() {
  const navigate = useNavigate();
  useEffect(() => {
    async function signinAsync() {
      signinRedirectCallback().then((profile) => {
        window.history.replaceState({}, window.document.title, window.location.origin + window.location.pathname);
        window.location = 'index.html';
        setProfileToLS(profile);

        if (profile?.state?.url) {
          window.location.href = profile.state.url;
        } else {
          window.location.href = window.location.origin + urlPath.waittingDocument.location;
        }
      });
      // setProfileToLS(profile);
    }
    signinAsync();
  }, [navigate]);

  return (
    <Space
      direction='vertical'
      style={{
        width: '100vw',
        textAlign: 'center',
        paddingTop: '50px'
      }}
    >
      <Spin size='large' />
    </Space>
  );
}
