// CONFIG CHO SERVER LIVE
export const url =
  process.env.NODE_ENV === 'production'
    ? `${process.env.REACT_APP_URL_API_PRODUCTION}/v1`
    : `${process.env.REACT_APP_URL_API_DEV}/v1`;

export const urlSocket = process.env.REACT_APP_URL_API_PRODUCTION;
export const masterData = `${process.env.REACT_APP_URL_API_PRODUCTION}/v1`;
export const urlIS4 =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_URL_API_IS4_PRODUCTION
    : process.env.REACT_APP_URL_API_IS4_DEV;

// CONFIG CHO SERVER TEST
// export const url = "http://10.8.0.73:6100/v1"; // api-live v2
// export const urlSocket = "http://10.8.0.72:44506"; // url socket
// export const masterData = "http://10.8.0.72:6100/v1";
// export const urlIS4 = "http://10.8.0.73:44302";

// CONFIG CHO SERVER LOCAL
// export const url = "http://localhost:5000/v1"; // api-local-v2
// export const urlSocket = "http://10.8.0.72:44506"; // url socket
// export const masterData = "http://10.8.0.72:6100/v1";
// export const urlIS4 = "http://10.8.0.73:44302";
