const getPath = (location, path) => {
  const home = {
    location,
    path
  };
  return home;
};

const urlPath = {
  home: getPath('/', ['eDocument']),

  //#region Document management
  documents: getPath('/documents', ['eDocument', 'Quản lý văn bản']),
  relativeDocument: getPath('/documents', ['eDocument', 'Văn bản liên quan']),
  documentAttach: getPath('/documents/attach-file', ['eDocument', 'Văn bản', 'Đính kèm']),
  waittingDocument: getPath('/waiting-documents', ['eDocument', 'Chờ thực hiện']),
  handledDocument: getPath('/handled-documents', ['eDocument', 'Đã thực hiện']),
  myDocument: getPath('/my-documents', ['eDocument', 'Văn bản của tôi']),
  storedDocument: getPath('/stored-documents', ['eDocument', 'Văn bản lưu trữ']),
  //#rendegion Document management

  //#region Document Administrator
  template: getPath('/templates', ['eDocument', 'Văn bản mẫu']),
  category: getPath('/category', ['eDocument', 'Nhóm văn bản']),
  departmentRights: getPath('/department-rights', ['eDocument', 'Quyền bộ phận']),
  bodRights: getPath('/bod-rights', ['eDocument', 'Quyền BOD']),
  bodRightUpdate: getPath('/bod-rights/edit', ['eDocument', 'Quyền BOD', 'Chỉnh sửa']),
  bodRightAdd: getPath('/bod-rights/new', ['eDocument', 'Quyền BOD', 'Thêm']),
  representative: getPath('/representative', ['eDocument', 'Người đại diện']),
  representativeAdd: getPath('/representative/new', ['eDocument', 'Người đại diện', 'Thêm']),
  representativeUpdate: getPath('/representative/edit', ['eDocument', 'Người đại diện', 'Chỉnh sửa']),
  approvalProcess: getPath('/approval-process', ['eDocument', 'Quy trình phê duyệt']),
  approvalProcessUpdate: getPath('/approval-process/edit', ['eDocument', 'Quy trình phê duyệt', 'Chỉnh sửa']),
  approvalProcessAdd: getPath('/approval-process/create', ['eDocument', 'Quy trình phê duyệt', 'Thêm']),
  formMail: getPath('/form-mail', ['eDocument', 'Form mail']),
  formMailFooter: getPath('/form-mail-footer', ['eDocument', 'Form mail footer']),
  formMailPartner: getPath('/form-mail-partner', ['eDocument', 'Form mail gửi đối tác']),
  formMailNotifyClosing: getPath('/form-mail-notify-closing', ['eDocument', 'Form mail thông báo chốt']),
  formMailNotifyReview: getPath('/form-mail-notify-review', ['eDocument', 'Form mail thông báo soát xét']),
  formMailNotifyLiquidation: getPath('/form-mail-notify-liquidation', [
    'eDocument',
    'Form mail thông báo biên bản hoàn tất'
  ]),
  handover: getPath('/handover', ['eDocument', 'Bàn giao']),

  //#rendegion Document Administrator

  //#region Document Support
  documentationGuide: getPath('/documentation-guide', ['eDocument', 'Tài liệu hướng dẫn']),
  //#rendegion Document Support

  //#region Document Report
  reportsByYear: getPath('/reports-by-year', ['eDocument', 'Báo cáo cả năm']),
  reportsByCategory: getPath('/reports-by-category', ['eDocument', 'Báo cáo theo nhóm HĐ']),
  //#rendegion Document Report

  //#region Editor Template
  createTemplate: getPath('/contract/template/create', ['eDocument', 'Tạo văn bản mẫu']),
  updateTemplate: getPath('/contract/template/update', ['eDocument', 'Chỉnh sửa văn bản mẫu']),
  //#rendegion Editor Template

  //#region Editor Document
  createDocument: getPath('/contract/create', ['eDocument', 'Khởi tạo hợp đồng']),
  updateDocument: getPath('/contract/edit', ['eDocument', 'Chỉnh sửa hợp đồng'])
  //#rendegion Editor Document
};

export default urlPath;
