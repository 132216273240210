import { http } from './http';

const controller = '/edoc/template-documents';

export const getTemplateDocuments = async (params) =>
  await http.get(`${controller}`, {
    params: params
  });

export const getTemplateDocument = async (id) => await http.get(`${controller}/${id}`);

export const addTemplateDocument = async (templateDocument) => await http.post(`${controller}`, templateDocument);

export const updateTemplateDocument = async (templateDocument) =>
  await http.put(`${controller}/${templateDocument.id}`, templateDocument);

export const addTemplateAppendixToTemplateDocument = async (templateAppendices, templateDocumentId) =>
  await http.post(`${controller}/add-template-appendix-to-template-document`, templateAppendices, {
    params: { id: templateDocumentId }
  });

export const deleteTemplateDocument = async (templateDocumentId) =>
  await http.delete(`${controller}/${templateDocumentId} `);

export const updateTemplateDocumentCheckChange = async (templateDocumentChildId, isCheckChange) =>
  await http.put(`${controller}/update-check-change/${templateDocumentChildId}`, {
    isCheckChange
  });

export const getHistoryOfTemplateDocumentChild = async (templateDocumentChildId, isGetAll) =>
  await http.get(`${controller}/history-template-document-child`, {
    params: { templateDocumentChildId, isGetAll }
  });
