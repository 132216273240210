import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Avatar, Button, Col, Form, Input, List, Modal, Row, Tabs, Typography } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getTemplateDocuments } from 'src/api/templateDocument.api';
import CategorySelect from 'src/components/Select/CategorySelect';
import { editorPageType } from 'src/constant';
import urlPath from 'src/constants/urlPath';
import AddDocumentIcon from 'src/style/image/add.png';
import DocumentIcon from 'src/style/image/docs.png';
import './index.css';
const { Search } = Input;

const styles = {
  typographyPara: {
    marginBottom: '4px',
    fontSize: '12px'
  }
};

export default function AddPrincipalDocument(props) {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [isPrinciple, setIsPrinciple] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [templates, setTemplates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const templateDocumentQuery = useQuery({
    queryKey: ['useTemplateDocuments', currentPage, selectedCategory, searchInput, isPrinciple],
    queryFn: () => {
      const params = {
        page: currentPage,
        categoryId: selectedCategory,
        searchCondition: searchInput,
        isPrinciple
      };
      return getTemplateDocuments(params);
    },
    staleTime: 60 * 1000 * 60,
    keepPreviousData: true
  });

  const ListDocument = () => {
    return (
      <List
        style={{
          height: '400px',
          overflow: 'auto',
          border: '1px solid rgba(140, 140, 140, 0.35)'
        }}
        loading={templateDocumentQuery.isLoading}
        bordered
        className='list-templates'
        dataSource={isPrinciple ? templates : [{ id: 0, title: 'Thêm mới' }, ...templates]}
        renderItem={(item) => {
          if (item.id === 0) {
            return (
              <List.Item onClick={() => onClick(item)}>
                <List.Item.Meta
                  avatar={<Avatar src={AddDocumentIcon} shape='square' size='large' />}
                  title={<Typography.Link>{item?.title || 'Thêm mới'}</Typography.Link>}
                />
              </List.Item>
            );
          } else {
            return (
              <List.Item onClick={() => onClick(item)}>
                <List.Item.Meta
                  avatar={<Avatar src={DocumentIcon} shape='square' size='large' />}
                  title={<Typography.Link>{item?.saveName}</Typography.Link>}
                  description={
                    <Typography.Text style={styles.typographyPara}>
                      {/* <Avatar src={AppendixIcon} shape="square" size="small" />{" "} */}
                      {t('dashboard.document.templateModal.category')}: {item?.categoryName ?? 'null'}
                    </Typography.Text>
                  }
                />
              </List.Item>
            );
          }
        }}
      />
    );
  };

  const items = [
    {
      key: '1',
      label: 'Hợp đồng Nguyên tắc',
      children: <ListDocument isPrinciple={true} />
    },
    {
      key: '2',
      label: 'Hợp đồng cụ thể',
      children: <ListDocument isPrinciple={false} />
    }
  ];

  const handleCancel = () => {
    props?.onOpen(false);
  };

  const onClick = (item) => {
    if (item?.id === 0) {
      navigate(urlPath.createDocument.location, {
        state: {
          docId: null,
          categoryId: selectedCategory,
          editorPageType: editorPageType.CREATE_DOCUMENT,
          isTemplate: false
        }
      });
    } else {
      navigate(urlPath.createDocument.location, {
        state: {
          docId: item?.id,
          categoryId: item?.categoryId,
          editorPageType: editorPageType.USE_TEMPLATE,
          isTemplate: true
        }
      });
    }
  };

  const onChangeTab = (tab) => {
    if (tab === '1') {
      setIsPrinciple(true);
    } else if (tab === '2') {
      setIsPrinciple(false);
    }
    setCurrentPage(1);
    queryClient.invalidateQueries({
      queryKey: ['useTemplateDocuments', currentPage, selectedCategory, searchInput, isPrinciple],
      exact: true
    });
  };

  const debouncedSearch = useMemo(() => debounce((term) => setSearchInput(term), 1000), []);

  useEffect(() => {
    if (templateDocumentQuery.data) {
      if (currentPage > 1) {
        setTemplates((prev) => [...prev, ...templateDocumentQuery.data?.data?.listTemplateDocument]);
      } else {
        setTemplates(templateDocumentQuery.data?.data?.listTemplateDocument);
      }
      setTotalPage(templateDocumentQuery.data?.data?.total);
    }
  }, [currentPage, templateDocumentQuery.data]);

  return (
    <Modal
      className='new-document-modal'
      open={props?.isOpen}
      title={t('dashboard.document.templateModal.heading')}
      zIndex={1040}
      onCancel={handleCancel}
      footer={null}
    >
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <Form.Item label={t('dashboard.document.templateModal.category')}>
            <CategorySelect
              style={{
                width: '100%'
              }}
              onChange={(value) => setSelectedCategory(value)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t('dashboard.document.templateModal.search')}>
            <Search
              allowClear
              onChange={(e) => debouncedSearch(e.target.value)}
              onSearch={(value) => setSearchInput(value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <Tabs defaultActiveKey='1' items={items} onChange={onChangeTab} />
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }} justify='center'>
        <Col>
          <Button
            disabled={(currentPage + 1) * 8 > totalPage}
            loading={templateDocumentQuery.isLoading}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            {t('dashboard.document.templateModal.moreBtn')}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
