import { useQueries } from '@tanstack/react-query';
import { createContext, useState } from 'react';
import { getResourceConfig } from 'src/api/config.api';
import { getUserLogin } from 'src/api/userLogin.api';

const initialAppContext = {
  selectedMenuItem: undefined,
  setSelectedMenuItem: () => null,
  isProduction: true,
  setIsProduction: () => null
};

export const AppContext = createContext(initialAppContext);

export const AppProvider = ({ children }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(initialAppContext.selectedMenuItem);
  const [anchor, setAnchor] = useState(undefined);
  const [anchorChild, setAnchorChild] = useState(undefined);
  const [resourceConfig, setResourceConfig] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [userRole, setUserRole] = useState(undefined);
  const [hasRole, setHasRole] = useState(undefined);
  const [notifyMessage, setNotifyMessage] = useState(undefined);
  const profile = localStorage.getItem('profile');
  const queries = useQueries({
    queries: [
      {
        queryKey: ['resourceConfig'],
        queryFn: () => getResourceConfig(),
        staleTime: 60 * 1000,
        enabled: !!profile,
        onSuccess: (response) => {
          setResourceConfig(response?.data);
        }
      },
      {
        queryKey: ['userLogin'],
        queryFn: () => getUserLogin(),
        staleTime: 60 * 1000,
        enabled: !!profile,
        onSuccess: (response) => {
          setUser({
            ...response.data?.employee,
            isPartner: response.data?.isPartner
          });
          setUserRole(response.data?.roles);

          setHasRole(!!response.data?.roles);
        }
      }
    ]
  });

  return (
    <AppContext.Provider
      value={{
        selectedMenuItem,
        setSelectedMenuItem,
        anchor,
        setAnchor,
        anchorChild,
        setAnchorChild,
        resourceConfig,
        user,
        userRole,
        notifyMessage,
        setNotifyMessage,
        hasRole: !queries?.[1]?.isError && hasRole
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
