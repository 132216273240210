import { Button, Flex, List, Modal, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { editorPageType } from 'src/constant';

import { useQuery } from '@tanstack/react-query';
import { getAlmostExpiredDocuments } from 'src/api/document.api';
import urlPath from 'src/constants/urlPath';
const { Text } = Typography;
export default function PrincipelExpiringModal({ open, setOpen }) {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const almostExpiredDocumentQuery = useQuery({
    queryKey: ['waitingDocument', page, true],
    queryFn: () => {
      const params = {
        page,
        isPrinciple: true
      };
      return getAlmostExpiredDocuments(params);
    },
    staleTime: 60 * 1000 * 60,
    keepPreviousData: true
  });

  const handleCancel = () => {
    setOpen(false);
  };

  const isExpired = (expirationDate) => {
    const now = new Date();
    const expiredDate = new Date(expirationDate);
    const result = expiredDate.getTime() < now.getTime();
    return result;
  };

  return (
    <Modal
      className='principle-expiring-modal'
      open={open}
      title='Hợp đồng nguyên tắc sắp hết hạn'
      zIndex={1050}
      okText='Đồng ý'
      cancelText='Không'
      onCancel={handleCancel}
      footer={null}
    >
      <Flex gap={10} vertical align='center'>
        <List
          style={{
            width: '100%',
            height: '600px',
            overflow: 'auto',
            border: '1px solid rgba(140, 140, 140, 0.35)'
          }}
          loading={almostExpiredDocumentQuery.isFetching}
          bordered
          dataSource={almostExpiredDocumentQuery.data?.data?.listDocument}
          pagination={{
            onChange: (page) => setPage(page),

            pageSize: 8,
            total: almostExpiredDocumentQuery.data?.data?.total
          }}
          renderItem={(item) => (
            <List.Item
              style={{ cursor: 'pointer' }}
              actions={[
                <Button
                  type='primary'
                  onClick={() => {
                    navigate(urlPath.createDocument.location, {
                      state: {
                        docId: item?.id,
                        categoryId: item?.categoryId,
                        editorPageType: editorPageType.USE_TEMPLATE,
                        documentPrincipleId: null,
                        informations: null,
                        isTemplate: false
                      }
                    });
                    setOpen(false);
                  }}
                >
                  Copy thành bản mới
                </Button>
              ]}
            >
              <List.Item.Meta
                title={<Link>{`ID: ${item.id} - ${item?.saveName || item?.title}`}</Link>}
                description={
                  <Flex vertical gap={10}>
                    <Text>{`Hiệu lực: ${dayjs(item.effectiveDate).format('DD/MM/YYYY')} - ${dayjs(
                      item.expirationDate
                    ).format('DD/MM/YYYY')}`}</Text>

                    <Tag style={{ width: 'fit-content' }} color={isExpired(item.expirationDate) ? '#f50' : '#b7ada8'}>
                      {isExpired(item.expirationDate) ? 'Đã hết hạn' : 'Sắp hết hạn'}
                    </Tag>
                  </Flex>
                }
              />
            </List.Item>
          )}
        />
      </Flex>
    </Modal>
  );
}
