import axios from 'axios';
import { getAccessTokenFromLS } from '../Authentication/auth';
import { showNotification } from 'src/utils/notification.js';
import { signinRedirect } from '../utils/auth';
import { masterData, url, urlIS4 } from '../constants/url';
import { HttpStatusCode } from 'src/constants/httpStatusCode.enum';

export const http = axios.create({
  baseURL: url,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*'
  }
});
export const httpMasterData = axios.create({
  baseURL: masterData,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*'
  }
});
export const httpIS4 = axios.create({
  baseURL: urlIS4,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*'
  }
});
const idToken = getAccessTokenFromLS();
http.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === HttpStatusCode.Unauthorized) {
      await signinRedirect();
    } else if (error.response?.status === HttpStatusCode.BadRequest) {
      showNotification(String(error.response.data), 'error');
    }
    return Promise.reject(error);
  }
);
httpMasterData.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
httpMasterData.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === HttpStatusCode.Unauthorized) {
      await signinRedirect();
    } else if (error.response?.status === HttpStatusCode.BadRequest) {
      showNotification(String(error.response.data), 'error');
    }
    return Promise.reject(error);
  }
);
httpIS4.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
httpIS4.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === HttpStatusCode.Unauthorized) {
      await signinRedirect();
    } else if (error.response?.status === HttpStatusCode.BadRequest) {
      showNotification(String(error.response.data), 'error');
    }
    return Promise.reject(error);
  }
);

// const http = new Http().instance;
