import { notification } from 'antd';

// type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const showNotification = (content, type = 'info') => {
  const capitalizedType = type ? type.charAt(0).toUpperCase() + type.slice(1) : '';

  switch (type) {
    case 'success':
      notification.success({
        message: capitalizedType,
        description: String(content),
        placement: 'bottomRight'
      });
      break;
    case 'info':
      notification.info({
        message: capitalizedType,
        description: String(content),
        placement: 'bottomRight'
      });
      break;
    case 'warning':
      notification.warning({
        message: capitalizedType,
        description: String(content),
        placement: 'bottomRight'
      });
      break;
    case 'error':
      notification.error({
        message: capitalizedType,
        description: String(content),
        placement: 'bottomRight'
      });
      break;
    default:
      notification.info({
        message: capitalizedType,
        description: String(content),
        placement: 'bottomRight'
      });
      break;
  }
};
