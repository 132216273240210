import { useMemo, useState } from 'react';
import { useBasePath } from './../../hook/useBasePath';
import { useNavigate, useParams } from 'react-router-dom';
import urlPath from 'src/constants/urlPath';
import { Breadcrumb as BreadcrumbAntd, Button, Flex } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

export default function Breadcrumb(className) {
  const [items, setItems] = useState([]);
  const basePath = useBasePath();
  const navigate = useNavigate();
  const { id } = useParams();

  useMemo(() => {
    const filtered = Object.entries(urlPath).filter(([key, value]) => {
      return value.location === basePath;
    });

    const newPath = filtered[0]
      ? filtered[0][1].path.map((item) => Object.assign({}, { title: item }))
      : [{ title: 'Not found' }];

    setItems(newPath);

    if (id) {
      if (typeof id === 'number') {
        setItems([...newPath, { title: String(id) }]);
      } else {
        const truncatedId = id.length > 5 ? `${id.substring(0, 8)}...` : id;
        setItems([...newPath, { title: truncatedId }]);
      }
    }
  }, [basePath, id]);

  return (
    <Flex align='center'>
      <Button
        type='ghost'
        onClick={() => navigate(-1)}
        style={{ marginLeft: '16px' }}
        icon={<ArrowLeftOutlined />}
      ></Button>
      <BreadcrumbAntd style={{ margin: '16px 0' }} className={className} items={items} />
    </Flex>
  );
}
