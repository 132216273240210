import { Spin } from 'antd';
import { useEffect } from 'react';
import { signoutRedirect } from 'src/utils/auth';

export default function SignoutOidc() {
  useEffect(() => {
    signoutRedirect();
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <Spin size='large' />
    </div>
  );
}
