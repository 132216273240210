import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { signoutRedirect } from 'src/utils/auth';

export default function ErrorNoRole() {
  const { t } = useTranslation();
  return (
    <Result
      title='Bạn không có quyền truy cập phần mềm eDocument! Vui lòng liên hệ bộ phận MIS để được hỗ trợ.'
      extra={[
        <Button type='primary' key='reload' onClick={() => window.location.reload()}>
          {t('header.user.reload')}
        </Button>,
        <Button type='primary' key='signout' onClick={signoutRedirect}>
          {t('header.user.logout')}
        </Button>
      ]}
    />
  );
}
