import { Button } from 'antd';
import React, { Fragment, useState } from 'react';
import PrincipelExpiringModal from '../Modal/PrincipelExpiringModal';

export default function PrincipleExpiringButton(props) {
  const [open, setOpen] = useState(false);
  const onClick = () => {
    setOpen(true);
  };
  return (
    <Fragment>
      <Button
        ariaLabel={props?.ariaLabel}
        style={props?.style}
        type={props?.type}
        size={props?.size}
        loading={props?.loading}
        onClick={onClick}
      >
        {props?.text}
      </Button>
      {open && <PrincipelExpiringModal setOpen={setOpen} open={open} />}
    </Fragment>
  );
}
