import { http } from './http';

const controller = '/edoc/categories';

export const getCategories = async (searchCondition, isAppendix) =>
  await http.get(`${controller}`, {
    params: { searchCondition, isAppendix }
  });
export const getCategoryDetail = async (categoryId) => await http.get(`${controller}/${categoryId}`);

export const addCategory = async (category) => await http.post(`${controller}`, category);

export const updateCategory = async (category) => await http.put(`${controller}/${category.id}`, category);

export const deleteCategory = async (categoryId) => await http.delete(`${controller}/${categoryId}`);
